<template>
<!-- 我的内推 -->
  <div class="list">
    <div class="header">
      <el-input
        style="width: 300px"
        prefix-icon="el-icon-search"
        @input="searchLodash"
        v-model="searchKey"
      ></el-input>
    </div>
    <el-table :data="tableData" @row-click="openPersonCvDialog">
      <el-table-column label="姓名 邮箱" width="350px">
        <template slot-scope="scope">
          <el-row style="line-height: 32px" :gutter="12">
            <el-col :span="6">
              <img
                :src="scope.row.avatar"
                alt=""
                style="width: 64px; height: 64px; border-radius: 50%"
              />
            </el-col>
            <el-col :span="18">
              <div>
                {{ scope.row.name }}

                <el-tag type="danger" v-if="scope.row.schoolfellow"
                  >校友</el-tag
                >
                <el-tag type="warning" v-if="scope.row.colleague"
                  >前同事</el-tag
                >
                <el-tag type="success" v-if="scope.row.targetCompany"
                  >目标公司</el-tag
                >
              </div>
              <div>{{ scope.row.email }}</div>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="毕业院校">
        <template slot-scope="scope">
          <div v-for="(school, index) in scope.row.school" :key="index">
            {{ school }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="工作经验"
        prop="workExperience.name"
      ></el-table-column>
      <el-table-column label="求职目标" prop="target.name"></el-table-column>
      <el-table-column label="刷题量" prop="questions"></el-table-column>
      <el-table-column label="简历" prop="questions">
        <template slot-scope="scope">
          <el-button type="text" @click.stop="downloadPDF(scope.row)"
            >点击下载</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <template v-if="scope.row.status && scope.row.status.value == 0">
            <div
              class="opBtn1"
              v-if="!scope.row.targetCompany"
              @click="invite(scope.row)"
            >
              邀约
            </div>
            <div class="opBtn2" v-else @click="invite(scope.row)">内推</div>
          </template>
          <template v-else>
            {{ scope.row.status.name }}
            <el-button
              type="text"
              v-if="scope.row.status && scope.row.status.value == 2"
              @click.stop="openCommunicationDialog(scope.row)"
              >查看进度</el-button
            >
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="currentPage"
      :total="total"
      layout="prev, pager, next"
      @current-change="getData"
    >
    </el-pagination>
    <PersonCvDialog
      :personId="currentId"
      :visible="personCvDialogVisible"
      @changeDialogVisible="(val) => (personCvDialogVisible = val)"
    ></PersonCvDialog>
    <CommunicationDialog
      :visible="communicationDialogVisible"
      @changeDialogVisible="(val) => (communicationDialogVisible = val)"
      :currentId="currentComId"
    ></CommunicationDialog>
  </div>
</template>
<script>
import PersonCvDialog from "./personCvDialog";
import CommunicationDialog from "../communicationDialog";
import { getMyReferList, downloadPDF } from "@/service/refer";
import _ from "lodash";
export default {
  name: "list",
  components: { PersonCvDialog, CommunicationDialog },
  data() {
    return {
      tableData: [],
      total: 0,
      currentPage: 1,
      currentId: "",
      searchKey: "",
      personCvDialogVisible: false,
      communicationDialogVisible: false,
      currentComId: "",
    };
  },
  methods: {
    getData(page) {
      if (page) {
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getMyReferList({ current: this.currentPage, name: this.searchKey }).then(
        (res) => {
          if (res.success) {
            this.tableData = res.result.records;
            this.total = res.result.total;
          }
        }
      );
    },
    openPersonCvDialog(item) {
      this.currentId = item.referCandidateId;
      this.personCvDialogVisible = true;
    },
    searchLodash: _.debounce(function () {
      this.getData(1);
    }, 1000),
    downloadPDF(item) {
      let fileName = `${item.name}.pdf`;
      let objectUrl =
        process.env.VUE_APP_BASE_URL + `/referEmployee/download/${item.id}`;
      var a = document.createElement("a");
      if (typeof a.download === "undefined") {
        window.location = objectUrl;
      } else {
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    },
    openCommunicationDialog(item) {
      this.communicationDialogVisible = true;
      this.currentComId = item.id;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.header {
  padding: 12px 20px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #8894ab;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}
.red {
  background: #ff0000;
}
.yellow {
  background: #fab600;
}
.green {
  background: #08a900;
}
::v-deep .el-table td,
::v-deep .el-table th {
  padding: 20px;
}
::v-deep .el-table th {
  background: rgba(244, 244, 244, 1);
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 14px;
}
::v-deep .el-pagination {
  text-align: center;
  padding: 20px;
}
.opBtn1 {
  background: rgba(88, 86, 214, 0.2);
  border-radius: 9px;
  padding: 4px 16px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #5856d6;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
}
.opBtn2 {
  background: rgba(10, 122, 255, 0.3);
  border-radius: 9px;
  padding: 4px 16px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
}
.tipspan {
  padding: 2px 6px;
  border-radius: 2px;
  margin-left: 4px;
  background: transparent !important;
  &.red {
    color: #ff0000;
    border: 1px solid #ff0000;
  }
  &.yellow {
    color: #fab600;
    border: 1px solid #fab600;
  }
  &.green {
    color: #08a900;
    border: 1px solid #08a900;
  }
}
::v-deep .el-tag {
  line-height: 22px;
  height: 22px;
  margin-right: 12px;
}
::v-deep .el-table__row {
  cursor: pointer;
}
</style>